const onIntersection = (element, entries, observer) => {  
    let progress = 0;
    if(entries[0].isIntersecting && entries[0].boundingClientRect.top > 0) {
        progress = entries[0].intersectionRatio;
        if(progress > 0.99) {
            element.classList.add('visible');
        } else {
            element.classList.remove('visible');
        }
    } else if (entries[0].boundingClientRect.top <= 0) {
        element.classList.add('visible');
        progress = 1;
    }
    requestAnimationFrame(() => {
        element.style.setProperty(
            '--progress', 
            progress
        );
    });
}

export function initTextOnImage() {   
    const elements = Array.from(document.querySelectorAll('.text-on-image') || []);
    const stepCount = 100;
    const steps = Array(stepCount).fill().map((_, i) => (i+1)/stepCount);

    elements.map((element) => {
        const observer = new IntersectionObserver(
            onIntersection.bind(this, element), 
            {
                threshold: steps,
                rootMargin: '0px 0px -10% 0px',
            }
        );
        observer.observe(element);
    });
    
}
  