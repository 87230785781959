import jsVectorMap from 'jsvectormap'
import 'jsvectormap/dist/maps/world.js'

export function initAnnualReportMap() {
    const elements = Array.from(document.querySelectorAll('.annual-report-map') || []);

    elements.forEach((element) => {
        const mapWrapper = element.querySelector('.annual-report-map__map-container');
        const dataScript = element.querySelector('script');
        const data = JSON.parse(dataScript.text);

        const buildPopup = (properties) => {
            const buildPopulationTable = (populations) => Object.entries(populations).map(([year, population]) => {
                return (/*html*/`
                    <div class="populations__col">
                        <div class="populations__year">
                            <b>
                                ${year}
                            </b>
                        </div>
                        <div class="populations__value">
                            ${population}
                        </div>
                    </div>
                `)
            });

            return /*html*/ `
                <div class="annual-report-map__popup">
                    <div class="annual-report-map__country">
                        <i class="wilo-icon icon-marker"></i> <b>${properties.name}</b>
                    </div>
                    ${properties.population ? 
                        (`<div class="annual-report-map__populations">
                            ${buildPopulationTable(properties.population).join("")}
                        </div>`) 
                        : ''
                    }
                    ${properties.link ? 
                        (`<a class="annual-report-map__cta" href="${properties.link.url}">${properties.link.text}</a>`) 
                        : ''
                    }
                </div>
            `
        };
        
        const map = new jsVectorMap({
            selector: `#${mapWrapper.id}`,
            map: 'world',
            zoomMax: 4,
            zoomMin: 1,
            showTooltip: true,
            zoomOnScroll: false,
            regionsSelectable: false,
            markersSelectable: true,
            markersSelectableOne: true,
            regionStyle: {
                initial: {
                    cursor: 'auto',
                },
                hover: {   
                    fillOpacity: 1,
                    cursor: 'auto',
                }
            },
            markerStyle: {
                initial: {
                    r: 10,
                    fill:  '#374151',
                },
                hover: {   
                    fill:  '#374151',
                    stroke: '#009c82',
                },
                selected: {
                    fill: '#374151',
                }
            },
            visualizeData: {
                scale: ['#FDF3D8', '#FDF3D8'],
                values: data.reduce((accumulated, current) => {
                    if(current.countryAbbreviation) {
                        accumulated[current.countryAbbreviation] = Math.floor(Math.random() * 100);
                    }
                    return accumulated;
                }, {})
            },
            onLoaded(map) {
                window.addEventListener('resize', () => {
                    map.updateSize()
                });
                element.addEventListener('click', (event) => {
                    if(!event.target.classList.contains('jvm-marker')) {
                        const tooltipElement = document.querySelector('.jvm-tooltip--fake');
                        tooltipElement.classList.remove('active');
                    }
                });
                element.querySelector('svg').addEventListener('touchmove', () => {
                    const tooltipElement = document.querySelector('.jvm-tooltip--fake');
                    tooltipElement?.classList.remove('active');
                });
            },
            onViewportChange(scale, transX, transY) {
                const tooltipElement = document.querySelector('.jvm-tooltip--fake');
                tooltipElement?.classList.remove('active');
            },
            markers: data.map((item) => ({
                name: item.name,
                coords: [item.coordinates[1], item.coordinates[0]],
                data: {
                    population: item.population,
                }
            })),
            onRegionTooltipShow(event, tooltip, code) {
                // Hide for all
                event.preventDefault();
                tooltip.hide();
            },
            onMarkerTooltipShow(event, tooltip, code) {
                event.preventDefault();
            },
            onMarkerClick(event, markerIndex) {
                const hiddenTooltipElement = map._tooltip.getElement();
                let tooltipElement = document.querySelector('.jvm-tooltip--fake');
                if(!tooltipElement) {
                    tooltipElement = document.createElement('div');
                    tooltipElement.classList.add('jvm-tooltip');
                    tooltipElement.classList.add('jvm-tooltip--fake');
                    hiddenTooltipElement.parentNode.insertBefore(tooltipElement, hiddenTooltipElement);
                }

                tooltipElement.innerHTML = buildPopup(data[markerIndex]);
                tooltipElement.style.top = hiddenTooltipElement.style.top;
                tooltipElement.style.left = hiddenTooltipElement.style.left;
                tooltipElement.classList.add('active');
            }

        });
    });

}
  