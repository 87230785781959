function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  return (
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

let lastKnownScrollPosition = 0;
let ticking = false;

export function scaleFullWidthImage() {
  const background_full_width_image = document.getElementById(
    "background_full_width_image"
  );
  if (background_full_width_image) {
    window.addEventListener("scroll", () => {
      const wrapper = document.getElementsByClassName(
        "background_full_width_image"
      )[0];

      lastKnownScrollPosition = window.scrollY;
      // 80 == sticky header size
      let imageScrollY = lastKnownScrollPosition - (wrapper.offsetTop + 80);

      if (!ticking) {
        ticking = true;
        if (
          isInViewport(wrapper) &&
          imageScrollY > 0 &&
          imageScrollY <= background_full_width_image.height
        ) {
          requestAnimationFrame(() => {
            background_full_width_image.style.transform = `translateY(${imageScrollY}px)`;
            ticking = false;
          });
        } else {
          requestAnimationFrame(() => {
            background_full_width_image.style.transform = `translateY(${0}px)`;
            ticking = false;
          });
        }
      }
    });
  }
}
