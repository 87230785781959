import offCanvas from "../offCanvas";

const rttHandlerApi = {
    alternateLocationsStorageKey: 'wilo-alternate-locations-',
    lastVisitedCountrySiteStorageKey: 'wilo-last-visited-country-site',
    closedRetargetingStorageKey: 'wilo-retargeting-closed',
    init: function () {
        // nothing to do for now
    },
    hookForRTT: function (country) {
        console.log('given country: ' + country);
        if (
            country &&
            window.wiloconfig.rtt &&
            window.wiloconfig.rtt.locationTargetingIsEnabled === true
        ) {
            this.handleLocation(country);
        }
    },
    handleLocation: function (locationSegment) {
        const currentCountry = (window).wiloconfig.country.country;
        const currentLocation = locationSegment.toString().replace('v_location_segment_', '');
        const teaserElement = document.getElementById('location-off-canvas-template');
        const closedRetargeting = localStorage.getItem(this.closedRetargetingStorageKey);

        //check if retargeting has been closed
        if (!closedRetargeting) {
            // Check of offCanvas is available
            if (teaserElement !== undefined && teaserElement !== null) {
                // Check if current location match current country site
                if (currentLocation !== currentCountry) {
                    // Check if an alternate location was all ready set
                    if (!this.checkForAlternateLocation(currentLocation, currentCountry)) {
                        // Show alternate location teaser in offCanvas
                        this.showAlternateCountrySiteTeaser(currentLocation, currentCountry);
                    }
                }
            }
        }
    },
    handleRetargetingClose: function() {
        localStorage.setItem(this.closedRetargetingStorageKey, 'true');
    },
    showAlternateCountrySiteTeaser: function (currentLocation, currentCountry) {
        const locationTeaserTemplate = document.getElementById('location-off-canvas-template');
        const translations = this.getTranslations();
        offCanvas.showOffCanvas(locationTeaserTemplate);
        locationTeaserTemplate.style.display = 'flex';
        document.querySelector('.locationRetargeting__text').innerText = translations.text.replace('{x}', currentCountry.toUpperCase());
        if (!wiloconfig.webResourcePath) {
            document.querySelector('.locationRetargeting__flag').src = './resources/media/countryFlags/' + currentLocation.toUpperCase() + '_Flag.svg';
        }
        else {
            document.querySelector('.locationRetargeting__flag').src = window.wiloconfig.webResourcePath + 'media/countryFlags/' + currentLocation.toUpperCase() + '_Flag.svg';
        }
        
        this.addVisitAlternateCountrySiteButton(currentLocation, currentCountry, translations);
        this.addOverviewButton(currentLocation, currentCountry, translations);
        this.addListenerToCloseBtn();
    },
    getTranslations: function () {
        //first get supported label-languages
        let supportedLanguages = Object.keys(window.wiloRttConfig.labels);
        let getLanguage = getInitialBrowserLanguage();
        if (!supportedLanguages.includes(getLanguage.toLocaleLowerCase())) {
            getLanguage = 'en';
        }

        return window.wiloRttConfig.labels[getLanguage.toLowerCase()]
    },
    checkForAlternateLocation: function (currentLocation, currentCountry) {
        const alternateLocation = localStorage.getItem(this.alternateLocationsStorageKey + currentCountry.toString().toUpperCase());
        let alternateLocationExists = false;

        if (alternateLocation !== null) {
            if (alternateLocation === currentCountry.toString().toUpperCase()) {
                alternateLocationExists = true;
            }
        }

        return alternateLocationExists;
    },
    saveAlternateLocation: function (currentCountry, alternateLocation) {
        const alternateLocationKey = this.alternateLocationsStorageKey + currentCountry.toString().toUpperCase();
        localStorage.setItem(alternateLocationKey, alternateLocation.toString().toUpperCase());
    },
    addOverviewButton: function (currentLocation, currentCountry, translations) {
        const btnOverviewUrl = window.wiloRttConfig.urls['overview'];
        const overview = document.querySelector('.locationRetargeting__overview');
        const country = document.querySelector('.locationRetargeting__country');
        overview.href = btnOverviewUrl;
        country.innerText = window.wiloRttConfig.countries[currentLocation];
    },
    addVisitAlternateCountrySiteButton: function (currentLocation, currentCountry, translations) {
        const btn = document.querySelector('.locationRetargeting__link');
        const __this = this;
        btn.innerText = translations.btnCountrySite.replace('{x}', currentLocation.toUpperCase());
        btn.addEventListener('click', function (event) {
            event.preventDefault();
            __this.saveAlternateLocation(currentCountry, currentLocation);
            offCanvas.hideOffCanvas();
            window.location.href = window.wiloRttConfig.urls[currentLocation.toString().toLowerCase()];
        });
    },
    addListenerToCloseBtn: function () {
        const closebtn = document.querySelector('.locationRetargeting__close');
        const __this = this;
        closebtn.addEventListener('click', function (event) {
            event.preventDefault();
            __this.handleRetargetingClose();
        });
    }
};
const wiloRTTBridge = {
    init: function (rttHandlerApi) {
        rttHandlerApi.init();
        if (wiloconfig.rtt.previewMode) {
            console.log("RTT in preview-mode.");
            let queryParams = {};
            location.search.substr(1).split("&").forEach(function (item) {
                const key = item.split("=")[0], value = decodeURIComponent(item.split("=")[1]);
                (key in queryParams) ? queryParams[key].push(value) : queryParams[key] = [value];
            });
            if ("country" in queryParams) {
                rttHandlerApi.hookForRTT(queryParams.country);
            } else {
                console.log("RTT in preview-mode, but no segments to fake given (rtt_segment), reverting to normal mode");

                this.setupRTT(rttHandlerApi);
            }
        } else {
            this.setupRTT(rttHandlerApi);
        }
    },
    setupRTT: function (rttHandlerApi) {
        if (!wiloconfig.rtt || !wiloconfig.rtt.endpointUrl) {
            console.warn("wiloconfig.rtt or wiloconfig.rtt.endpointUrl not defined");

            return;
        }

        fetch(wiloconfig.rtt.endpointUrl)
            .then(response => {
                if (!response.ok) {
                    throw new Error("got non-ok response with status code", response.status);
                }

                return response.json();
        }).then(function(data) {
            if (!data || !data.iso_code) {
                console.error("country or the iso_code is not defined.");

                return;
            }
            rttHandlerApi.hookForRTT(data.iso_code);
        }).catch(function(error) {
            console.error("got non-ok response from country detection api", error);
        });
    },
};


wiloRTTBridge.init(rttHandlerApi);

const wiloLocationApi = {
    handleAlternateLocation: function(countryKey) {
        let key = countryKey.toString().toLowerCase();
        window.location.href = window.wiloRttConfig.urls[key];
    }
};

function getInitialBrowserLanguage() {
    // get browsers language
    let browserLang = navigator.language.slice(0, 2);
    if (browserLang === undefined || browserLang === null) {
        if (navigator.userLanguage) {
            browserLang = navigator.userLanguage.slice(0, 2);
        }
        else 
        browserLang = 'en';
    }

    return browserLang.toUpperCase();
};


window.wiloLocationApi = wiloLocationApi;
