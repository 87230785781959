export function addClassWhenInView(selector, className, threshold) {   
    const onIntersection = (element, entries, observer) => {
        
        if(entries[0].isIntersecting) {
            element.classList.add(className);
        } else if(!entries[0].isIntersecting && entries[0].boundingClientRect.top > 0) {
            element.classList.remove(className);
        }
    }

    const elements = Array.from(document.querySelectorAll(selector) || []);

    elements.map((element) => {
        const observer = new IntersectionObserver(
            onIntersection.bind(this, element), 
            {
                threshold: threshold,
                rootMargin: '0px 0px -10% 0px',
            }
        );
        observer.observe(element);
    });
}