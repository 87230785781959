// import "./tailwind.scss";

import "shariff";
import AOS from 'aos';

// To provide some sense of a "starting point".
function start150Years() {
  initializeAOS();
  initializeLottie();
}

function initializeAOS() {
  AOS.init({
    startEvent: "load",
  });

  window.addEventListener("load", () => {
    setTimeout(() => {
      AOS.refresh();
    }, 1000);
  });
}

function initializeLottie() {
  const firstBreakPoint = innerHeight < 2000 ? [900, 0.64] : [2000, 0.39];
  const secondBreakPoint = innerHeight < 2000 ? [2000, 0.39] : [5000, 0.25];

  const milestonesLottieBreak = getBreakPointForHeight(
    firstBreakPoint,
    secondBreakPoint,
    innerHeight
  );

  for (let element of document.getElementsByClassName("milestone__lottie")) {
    const id = element.id;
    if (!id) {
      continue;
    }
    createLottie(`#${id}`, () => ({
      mode: "scroll",
      actions: [
        {
          visibility: [0, 0.09],
          type: "stop",
          frames: [-15],
        },
        {
          visibility: [0.09, milestonesLottieBreak],
          type: "seek",
          frames: [-15, 595],
        },
        {
          visibility: [milestonesLottieBreak, 1],
          type: "stop",
          frames: [595],
        },
      ],
    }));
  }

  const firstStartBreakPoint = innerHeight < 2000 ? [900, 0.35] : [2000, 0.2];
  const secondStartBreakPoint = innerHeight < 2000 ? [2000, 0.2] : [5000, 0.1];

  const milestonesLottieStartBreak = getBreakPointForHeight(
    firstStartBreakPoint,
    secondStartBreakPoint,
    innerHeight
  );

  createLottie(`#milestonesLottieStart`, () => ({
    mode: "scroll",
    actions: [
      {
        visibility: [0, milestonesLottieStartBreak],
        type: "seek",
        frames: [0, 200],
      },
      {
        visibility: [milestonesLottieStartBreak, 1],
        type: "stop",
        frames: [200],
      },
    ],
  }));
}

/**
 * Wraps around the default Lottie Interactivity creator to eagerly fetch the player element
 * and make it available in the configuration callback.
 *
 * Fails silently if the player element is not found.
 *
 * @param {string} id - The player id.
 * @param {function({player?: HTMLElement, id?: string}): {}} cb - The config supplying callback.
 */
function createLottie(id, cb) {
  const player = document.getElementById(id.replace("#", ""));
  if (!player) {
    return;
  }
  const config = cb({ id, player });
  return LottieInteractivity.create({
    player: id,
    ...config,
  });
}

/**
 * Interpolate between breakpoint1 and breakPoint2 to get a value for height.
 * @param breakPoint1 [height1, breakpoint1]
 * @param breakPoint2 [height2, breakpoint2]
 * @param height
 * @returns {number}
 */
export function getBreakPointForHeight(breakPoint1, breakPoint2, height) {
  return lerp2D(
    breakPoint1,
    breakPoint2,
    -(breakPoint1[0] - height) / (breakPoint2[0] - breakPoint1[0])
  )[1];
}

/**
 * Computes the linear interpolation between a and b for the parameter t.
 * @param {number} x1
 * @param {number} y1
 * @param {number} x2
 * @param {number} y2
 * @param {number} t
 * @returns {[number, number]}
 */
export function lerp2D([x1, y1], [x2, y2], t) {
  return [lerp(x1, x2, t), lerp(y1, y2, t)];
}

/**
 * Computes the linear interpolation between a and b for the parameter t.
 * @param {number} a
 * @param {number} b
 * @param {number} t
 * @returns {number}
 */
export function lerp(a, b, t) {
  return a + (b - a) * t;
}

// Run everything.
start150Years();

export { start150Years  };
