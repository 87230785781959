const ANIMATION_NAMES = {
    de: {
        people_1mio: "media/lotties/annualReport2023/people_1mio.json",
        people_2mio: "media/lotties/annualReport2023/people_2mio.json",
        people_10mrd: "media/lotties/annualReport2023/people_10mrd.json",
        bottle: "media/lotties/annualReport2023/bottle.json",
        city_prozent: "media/lotties/annualReport2023/city_prozent.json",
        city_zahl: "media/lotties/annualReport2023/city_zahl.json",
        london: "media/lotties/annualReport2023/london.json",
        nairobi: "media/lotties/annualReport2023/nairobi.json",
        neom: "media/lotties/annualReport2023/neom.json",
        nusantara: "media/lotties/annualReport2023/nusantara.json",
        alatau: "media/lotties/annualReport2023/alatau.json",
        creating: "media/lotties/annualReport2023/creating.json",
        caring: "media/lotties/annualReport2023/caring.json",
        connecting: "media/lotties/annualReport2023/connecting.json"
    },
    en: {
        people_1mio: "media/lotties/annualReport2023/people_1mio_en.json",
        people_2mio: "media/lotties/annualReport2023/people_2mio_en.json",
        people_10mrd: "media/lotties/annualReport2023/people_10mrd_en.json",
        bottle: "media/lotties/annualReport2023/bottle_en.json",
        city_prozent: "media/lotties/annualReport2023/city_prozent.json",
        city_zahl: "media/lotties/annualReport2023/city_zahl.json",
        london: "media/lotties/annualReport2023/london_en.json",
        nairobi: "media/lotties/annualReport2023/nairobi.json",
        neom: "media/lotties/annualReport2023/neom.json",
        nusantara: "media/lotties/annualReport2023/nusantara_en.json",
        alatau: "media/lotties/annualReport2023/alatau_en.json",
        creating: "media/lotties/annualReport2023/creating.json",
        caring: "media/lotties/annualReport2023/caring.json",
        connecting: "media/lotties/annualReport2023/connecting.json"
    }
}

function initPlayerElement(parentContainer, pathToLottieFile) {
    let player = parentContainer.querySelector('lottie-player');
    if(!player) {
        player = document.createElement('lottie-player');
        parentContainer.prepend(player);
    }
    
    player.load(pathToLottieFile);
    player.speed = 0.75;
    return player;
}

export function initTextLottie() {   
    const elements = Array.from(document.querySelectorAll('.text-lottie') || []);
    
    elements.forEach((element) => {
        const playerWrapper = element.querySelector('.text-lottie__lottie');
        const animationName = element.dataset.animationName;
        const animationFilePath = wiloconfig.webResourcePath + ANIMATION_NAMES[wiloconfig.country.lang][animationName];
        const player = initPlayerElement(playerWrapper, animationFilePath);

        const observer = new IntersectionObserver(
            (entries, observer) => {
                if(entries[0].isIntersecting) {
                    player.play();
                }
            }, 
            { threshold: 1.0 }
        );
        observer.observe(player);

    });
}
  