/* POLYFILLY FIRST! */

import "./vendor/polyfills/polyfill";
import "./iframe";

// General Functions
NodeList.prototype.forEach = Array.prototype.forEach;
Node.prototype.find = function (selector) {
  return $(selector, this);
};
import "./maintenance";
import "../../resources/js/dev/wilo-config";
import "./rtt/rtt";

import "../scss/scss.config";

/* Application imports & code */
import ScrollReveal from "scrollreveal";
import { tns } from "tiny-slider";
import Glide from "@glidejs/glide";
import { scaleFullWidthImage } from "./st_fullWidthImage";
import Shariff from "shariff";

/* Scripts from 150 years project */
import "./150years.js";
import { addClassWhenInView } from "./annualReport2023.js"; 
import { initTextOnImage } from "./st_text_on_image.js";
import { initTextLottie } from "./st_text_lottie.js";
import { initAnnualReportMap } from "./st_annual_report_map.js";

var $ = function (selector, context) {
  var elems = (context || document).querySelectorAll(selector);
  if (elems.length > 1) {
    return elems;
  } else {
    return elems[0];
  }
};

initKeyFiguresTeasers();

function hasClass(elem, className) {
  if (!elem) return;
  return elem.classList
    ? elem.classList.contains(className)
    : new RegExp("\\b" + className + "\\b").test(elem.className);
}

function addClass(elem, className) {
  if (!elem) return;
  if (elem.classList) elem.classList.add(className);
  else if (!hasClass(elem, className)) elem.className += " " + className;
}

function removeClass(elem, className) {
  if (!elem) return;
  if (elem.classList) elem.classList.remove(className);
  else
    elem.className = elem.className.replace(
      new RegExp("\\b" + className + "\\b", "g"),
      ""
    );
}

function toggleClass(elem, className) {
  hasClass(elem, className)
    ? removeClass(elem, className)
    : addClass(elem, className);
}

function slideToggle(elem) {
  toggleClass(elem, "slideToggle");
}

function toggle(elem) {
  toggleClass(elem, "hidden");
}

function getElements(selector, context) {
  return (context || document).querySelectorAll(selector);
}

function getElement(selector, context) {
  return (context || document).querySelector(selector);
}

function addEventListenerToItems(items, listener, listenerFunction) {
  items.forEach(function (elem) {
    elem.addEventListener(listener, listenerFunction, false);
  });
}

function composedPath(elem) {
  var path = [];
  while (elem) {
    path.push(elem);
    if (elem.tagName === "HTML") {
      path.push(document);
      path.push(window);
      return path;
    }
    elem = elem.parentElement;
  }
}

function parents(elem, index) {
  var parent = elem.parentNode;
  if (index > 1) {
    parent = parents(parent, index - 1);
  }
  return parent;
}

function parent(elem) {
  return elem.parentNode;
}

var checkViewPort = function (elem) {
  var bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
    (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
    (window.innerWidth || document.documentElement.clientWidth)
  );
};

// Scroll Reveal
var initScrollReveal = (function () {
  var fadeUp = {
    origin: "bottom",
    scale: 1,
    distance: "10%",
    duration: 750,
  };

  window.scrollReveal = ScrollReveal();
  document.querySelectorAll(".teaser").forEach(function (elem) {
    window.scrollReveal.reveal(elem, fadeUp, 50);
  });
})();

//Side Slider
var initSideSlider = (function () {
  var infoBox = $(".sidenav .info-box");
  if (typeof infoBox !== "undefined" && infoBox !== null) {
    if (window.scrollY <= 400) {
      removeClass(infoBox, "hide");
    }
    var toggleSideSlider = function () {
      if (window.scrollY > 400) {
        addClass(infoBox, "hide");
        window.removeEventListener("scroll", toggleSideSlider, false);
      }
    };
    window.addEventListener("scroll", toggleSideSlider, false);
  }
})();

//Full Size Slider
var initFullSizeTeaserSlider = (function () {
  getElements(".full-size-teasers").forEach(function (elem) {
    if (getElements(".full-size-teaser", elem).length > 1) {
      tns({
        container: elem,
        autoplay: true,
        autoplayTimeout: 7500,
        autoplayButtonOutput: false,
        speed: 700,
        mouseDrag: true,
        controls: false,
      });
    }
  });
})();

//Award Slider
var initAwardSlider = (function () {
  var sliderElement = document.getElementsByClassName("award-slider-inner");
  if (sliderElement.length !== 0) {
    tns({
      container: $(".award-slider-inner .slider"),
      controls: false,
      nav: false,
      autoplay: true,
      autoplayTimeout: 3000,
      autoplayButtonOutput: false,
      speed: 700,
      mouseDrag: true,
      autoplayHoverPause: true,
      responsive: {
        768: {
          items: 3,
        },
        992: {
          items: 4,
        },
        1200: {
          items: 5,
        },
      },
    });
  }

})();

// Menu
var initMenuToggle = (function () {
  var menuItems = getElements(".menu-items > li");
  addEventListenerToItems(menuItems, "click", function () {
    if (window.innerWidth < 992) {
      if (hasClass(this, "active")) {
        removeClass(this, "active");
      } else {
        menuItems.forEach(function (elem) {
          removeClass(elem, "active");
        });
        addClass(this, "active");
      }
    }
  });
  addEventListenerToItems(menuItems, "mouseover", function () {
    if (window.innerWidth > 991) {
      addClass(this, "active");
    }
  });
  addEventListenerToItems(menuItems, "mouseleave", function () {
    if (window.innerWidth > 991) {
      removeClass(this, "active");
    }
  });
  let burgerMenu = getElement(".burger");
  if (burgerMenu) {
    burgerMenu.addEventListener("click", function () {
      var body = document.querySelector("body");
      if (hasClass(this, "is-active")) {
        removeClass(this, "is-active");
        removeClass(body, "nav--open");
        scrollController.enableScroll();
      } else {
        addClass(this, "is-active");
        addClass(body, "nav--open");
        scrollController.disableScroll();
      }
      toggleClass(getElement(".menu-items-wrapper", this.parentElement), "flex");
    });
  }
})();
// Cookie Box
var initCookieBox = (function () {
  var box = getElement(".cookie-box");

  // Cookie request
  function checkCookiesAccepted() {
    if (document.cookie.indexOf("cookies=true") === -1) {
      addClass(box, "flex");
    }
  }

  checkCookiesAccepted();

  if (box) {
    getElement("#cookie-button").addEventListener("click", function () {
      createCookie("cookies", "true", "365");
      removeClass(box, "flex");
    });
  }

  function createCookie(name, value, days) {
    var date, expires;
    if (days) {
      date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toGMTString();
    } else {
      expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
  }
})();

// Tabs
var initTabs = (function () {
  var lastwidth = 0,
    tabSections = getElements(".tabs-wrapper"),
    initTabToggle = function () {
      addEventListenerToItems(
        getElements(".tabs-container"),
        "click",
        function (event) {
          if (this.querySelector(".tabs-wrapper.dropdown")) {
            toggleClass(this.querySelector(".burger"), "is-active");
            toggleClass(this.querySelector(".tabs-wrapper"), "flex");
          }
        }
      );
      addEventListenerToItems(
        getElements(".tabs-wrapper li"),
        "click",
        function () {
          var tabsContent = this.parentNode.parentNode.parentNode.parentNode;
          tabsContent.querySelector(".active-elemt").textContent =
            this.querySelector("p").textContent;
          removeClass(
            getElement(".tabs-wrapper li.active", tabsContent),
            "active"
          );
          addClass(this, "active");
          getElements(".tab-content", tabsContent).forEach(function (elem) {
            addClass(elem, "hidden");
          });
          removeClass(
            getElement("#" + this.getAttribute("data-tab")),
            "hidden"
          );
        }
      );
    },
    initTabDropdown = function () {
      var width = window.innerWidth;
      if (width > lastwidth && lastwidth > 0) {
        tabSections.forEach(function (tabs) {
          if (hasClass(tabs, "dropdown")) {
            removeClass(tabs, "dropdown");
            if (tabs.clientHeight > 75) {
              addClass(tabs, "dropdown");
            }
          }
        });
      } else {
        tabSections.forEach(function (tabs) {
          if (!hasClass(tabs, "dropdown")) {
            if (tabs.clientHeight > 75) {
              addClass(tabs, "dropdown");
            }
          }
        });
      }
      lastwidth = width;
    };

  initTabToggle();
  initTabDropdown();

  window.addEventListener("resize", initTabDropdown, false);
})();

// Accordeon
var initAccordeon = (function () {
  var accordeons = getElements(".accordeon-wrapper");
  addEventListenerToItems(accordeons, "click", function () {
    var elem = this.parentNode;
    if (hasClass(elem, "active")) {
      removeClass(elem, "active");
    } else {
      var currElement = getElement(
        ".accordeon-element.active",
        elem.parentNode
      );
      if (currElement) {
        removeClass(currElement, "active");
      }
      addClass(elem, "active");
    }
  });
  addEventListenerToItems(
    getElements(".accordeon-toggle"),
    "click",
    function () {
      var elem = getElement(this.getAttribute("href"));
      var currActive = getElement(".accordeon-element.active", elem.parentNode);
      if (currActive) {
        removeClass(currActive, "active");
      }
      addClass(elem, "active");
    }
  );
})();

// AnnualReport
var initAnualReport = (function () {
  addEventListenerToItems(
    getElements(".ar-nav-item"),
    "click",
    function (event) {
      var nav = parents(this, 2);
      var active = getElement("li.active", nav);
      if (
        window.innerWidth < 992 &&
        (active === parent(event.target) || active === parents(event.target, 2))
      ) {
        toggleClass(active, "active");
        toggleClass(nav, "active");
      } else {
        if (active) {
          removeClass(active, "active");
        }
        addClass(parent(this), "active");
        addClass(nav, "active");
      }
    }
  );
  addEventListenerToItems(getElements(".ar-nav-back"), "click", function () {
    removeClass(parents(this, 3), "active");
  });
})();

// Dropdowns
var initDropdowns = (function () {
  addEventListenerToItems(
    getElements(".wilo-custom-select"),
    "click",
    function () {
      toggleClass(parent(this), "active");
    }
  );

  addEventListenerToItems(
    getElements(".wilo-custom-dropdown-menu li"),
    "click",
    function () {
      var dropdown = parents(this, 2);
      removeClass(dropdown, "active");
      getElement(".wilo-btn", dropdown).innerHTML = this.innerHTML;
    }
  );
})();

// ScrollControler
var scrollController = (function () {
  function preventDefaultForScrollKeys(e) {
    var scrollKeys = {
      37: 1,
      38: 1,
      39: 1,
      40: 1,
    };
    if (scrollKeys[e.keyCode]) {
      preventDefault(e);
      return false;
    }
  }

  function preventDefault(e) {
    e = e || window.event;
    if (e.preventDefault) e.preventDefault();
    e.returnValue = false;
  }

  return {
    disableScroll: function () {
      if (window.addEventListener)
        window.addEventListener("DOMMouseScroll", preventDefault, false);
      window.onwheel = preventDefault;
      window.onmousewheel = document.onmousewheel = preventDefault;
      window.ontouchmove = preventDefault;
      document.onkeydown = preventDefaultForScrollKeys;
    },
    enableScroll: function () {
      if (window.removeEventListener)
        window.removeEventListener("DOMMouseScroll", preventDefault, false);
      window.onmousewheel = document.onmousewheel = null;
      window.onwheel = null;
      window.ontouchmove = null;
      document.onkeydown = null;
    },
  };
})();

/**
 * Reads all query params from the url
 */
var fetchQueryParams = function () {
  var match,
    pl = /\+/g,
    search = /([^&=]+)=?([^&]*)/g,
    decode = function (s) {
      return decodeURIComponent(s.replace(pl, " "));
    },
    query = window.location.search.substring(1);

  var urlParams = {};
  while ((match = search.exec(query))) {
    urlParams[decode(match[1])] = decode(match[2]);
  }

  return urlParams;
};

/**
 * places the last part of the src-path for the career iframe
 * if specific queryparams are given then the iframe displays /details.html with those query params
 * else /index.html without queryparams is added
 * @param {*} iframeSrc the src attribte to manipulate
 * @param {*} queryParams the query params to append if available
 * returns the manipulated iframeSourceUrl
 */
var setJobIframeSourcePath = function (iframeSrc, queryParams) {
  if (queryParams && queryParams.jobId && queryParams.jobTitle) {
    document.title = "Wilo - " + queryParams.jobTitle;
    iframeSrc +=
      "/details.html?jobId=" +
      queryParams.jobId +
      "&jobTitle=" +
      queryParams.jobTitle;
  } else {
    iframeSrc += "/index.html";
  }
  return iframeSrc;
};

// CountUp
function animateValue(elem, config) {
  var end = parseFloat(elem.textContent);
  Math.easeOutQuad = function (t, b, c, d) {
    t /= d;
    return -c * t * (t - 2) + b;
  };

  function initCountUpConfig(config) {
    if (!config) {
      config = {};
    }
    return {
      duration: config.duration ? config.duration : 1000,
      startAt: config.startAt ? config.startAt : 0,
      stepSpeed: config.stepSpeed ? config.stepSpeed : 50,
    };
  }

  function renderOutput(value) {
    var lang = document.documentElement.lang;
    var val = value + "";
    if (lang === "" || lang === "en-GB") {
      return formatNumber(val, ",", ".");
    } else {
      return formatNumber(val, ".", ",");
    }
  }

  function formatNumber(val, symbol1, symbol2) {
    var numbers = val.split(".");
    var output = "";
    if (numbers[0].length > 3) {
      var decimals = numbers[0].split("");
      for (var i = 1; i <= Math.floor(decimals.length / 3); i++) {
        var currNumber = decimals.length - i * 3 - 1;
        decimals[currNumber] += symbol1;
      }
      for (var i = 0; i < decimals.length; i++) {
        output += decimals[i];
      }
    } else {
      output += numbers[0];
    }
    if (numbers[1]) {
      output += symbol2 + numbers[1];
    }
    return output;
  }

  if (!isNaN(end) && !hasClass(elem, "animate")) {
    addClass(elem, "animate");
    var elemConfig = initCountUpConfig(config),
      start = elemConfig.startAt,
      range = end - start,
      current = start,
      stepSpeed = elemConfig.stepSpeed,
      time = 0,
      decimalsCount = (function (value) {
        if (Math.floor(value) !== value)
          return value.toString().split(".")[1].length || 0;
        return 0;
      })(end),
      stepSize = 0.1,
      timer = setInterval(function () {
        if (end > current) {
          current =
            parseFloat(current) +
            Math.easeOutQuad(time, start, range, elemConfig.duration * 100);
          current = current.toFixed(decimalsCount);
        }
        if (end < current) {
          current = end;
        }
        time += stepSpeed;
        elem.innerHTML = renderOutput(current);
        if (current == end) {
          clearInterval(timer);
        }
      }, stepSpeed);
  }
};

// Reveal Teaser
function initKeyFiguresTeasers() {
  window.addEventListener(
    "scroll",
    function () {
      getElements(".count-up").forEach(function (elem) {
        if (checkViewPort(elem)) {
          animateValue(elem);
        }
      });
    },
    false
  );
}

//Career
if (document.querySelector(".career")) {
  var lang = document.documentElement.lang;
  if (lang === "de-DE") {
    lang = "de";
  } else {
    lang = "en";
  }
  var queryParams = fetchQueryParams();
  var iframeSrc;
  if (!wiloconfig.webResourcePath) {
    iframeSrc =
      // window.location.origin +
      "./resources/career/FO/P7UFK026203F3VBQB688N7VU3/components/" +
      lang;
    iframeSrc = setJobIframeSourcePath(iframeSrc, queryParams);
    document.querySelector(".career").setAttribute("src", iframeSrc);
  } else {
    iframeSrc =
      // window.location.origin +
      wiloconfig.webResourcePath +
      "./career/FO/P7UFK026203F3VBQB688N7VU3/components/" +
      lang;
    iframeSrc = setJobIframeSourcePath(iframeSrc, queryParams);
    document.querySelector(".career").setAttribute("src", iframeSrc);
  }
  var iframes = window.iFrameResize(
    {
      autoResize: true,
    },
    ".career" || "iframe"
  );

  var eventMethod = window.addEventListener
    ? "addEventListener"
    : "attachEvent";
  var listener = window[eventMethod];
  var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

  // Listen to message from child window
  listener(
    messageEvent,
    function (e) {
      if (e.data === "overlay") {
        toggleClass($(".career-overlay"), "career-overlay--active");
        console.log(e);
      } else if (e.data === "remove-overlay") {
        removeClass($(".career-overlay"), "career-overlay--active");
      } else if (e.data === "add-overlay") {
        addClass($(".career-overlay"), "career-overlay--active");
      }
    },
    false
  );
}

// elastic connection (news_overview_page / rt_news_overview_stage.hbs)

var currentPage = 1;
const resultsPerPage = 9;

const language =
  window["wiloconfig"] && window["wiloconfig"]["haupia"]
    ? window["wiloconfig"]["haupia"]["lang"]
    : null;
const defaultContinentId =
  window["wiloconfig"] && window["wiloconfig"]["haupia"]
    ? window["wiloconfig"]["haupia"]["defaultContinentId"]
    : null;
const elasticConfig = window["wiloconfig"] && window["wiloconfig"]["elastic"]
  ? window["wiloconfig"]["elastic"]
  : null;
const elasticBaseUrl = elasticConfig && elasticConfig["baseUrl"]
  ? elasticConfig["baseUrl"]
  : "";
const elasticSearchIndex = elasticConfig && elasticConfig["searchIndex"]
  ? elasticConfig["searchIndex"]
  : "";
const elasticBaseAuthUser = elasticConfig && elasticConfig["auth"] && elasticConfig["auth"]["user"]
  ? elasticConfig["auth"]["user"]
  : "";
const elasticBaseAuthPw = elasticConfig && elasticConfig["auth"] && elasticConfig["auth"]["password"]
  ? elasticConfig["auth"]["password"]
  : "";

var currentContinentId = defaultContinentId;
var continentCountryMap = new Map();

/**
 * initalize news
 */
var initNews = function () {
  //TODO Ändern des queryselectors
  const newsButton = document.querySelector("[data-haupia-content-type]");
  if (newsButton) {
    getFilterResults(true);
  }
};

/**
 * @param {*} newSearch indicates if a search with a new filter is started
 * loads news documents from elastic, sorted by their publication date in descending order
 */
var getFilterResults = function (newSearch) {
  if (newSearch) {
    currentPage = 1;
  } else {
    currentPage += 1;
  }

  const requestUrl = elasticBaseUrl + elasticSearchIndex + "/_search";

  const elasticQuery = {
    query: {
      bool: {
        must: [
          {
            term: {
              content_type: "news",
            },
          },
          {
            term: {
              lang_code: language,
            },
          },
        ],
      },
    },
    sort: {
      publication_date_ms: {
        order: "desc",
      },
    },
    from: (currentPage - 1) * resultsPerPage,
    size: resultsPerPage,
  };

  fetch(requestUrl, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: `Basic ${btoa(
        elasticBaseAuthUser + `:` + elasticBaseAuthPw
      )}`,
      credentials: "include",
    },
    body: JSON.stringify(elasticQuery),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("HTTP status" + response.status);
      }
      return response.json();
    })
    .then((jsonResponse) => {
      if (
        jsonResponse.hits &&
        jsonResponse.hits.hits &&
        jsonResponse.hits.hits.length >= 0
      ) {
        setLoadMoreResultsButtonVisibility(jsonResponse.hits.total.value);
        generateNews(jsonResponse.hits.hits, newSearch);
        window.scrollReveal.sync();
      }
    });
};

/**
 * @param {*} news a nodelist of news or a single news element that should be discarded
 * hides the news elements to avoid flickering effects while deleting the old news and adding the ones
 */
var hideNewsToRemove = function (news) {
  if (news !== undefined) {
    if (news instanceof NodeList) {
      news.forEach(function (newsElem) {
        newsElem.style.opacity = 0;
      });
    } else {
      news.style.opacity = 0;
    }
  }
};

/**
 * @param {*} news a nodelist of news or a single news element that should be discarded
 * deletes the given news elements
 */
var deleteNews = function (news) {
  if (news !== undefined) {
    if (news instanceof NodeList) {
      news.forEach(function (newsElem) {
        newsElem.parentNode.removeChild(newsElem);
      });
    } else {
      news.parentNode.removeChild(news);
    }
  }
};

/**
 *
 * @param {*} newsArray the news data
 * @param {*} newSearch indicates if a search with a new filter is started
 * generates news from a teaser template
 * when newSearch is true the old news are hidden and deleted after append the new news (avoids flickering)
 */
var generateNews = function (newsArray, newSearch) {
  var oldNews = $(".news-teaser .teaser-outer");
  //hide the old news before deleting them to avoid flickering effects
  if (newSearch) {
    hideNewsToRemove(oldNews);
  }

  //add new news to the page
  newsArray.forEach(function (newsWrapper) {
    const news = newsWrapper._source;
    var teaser = $(".news-teaser-template").cloneNode(true);
    removeClass(teaser, "template");
    removeClass(teaser, "news-teaser-template");
    teaser.find("a").href = news.website_url;
    if (news.keyVisual) {
      teaser.find(".tile--image img").src = news.keyVisual;
      teaser.find(".tile--image img").title = news.keyVisualTitle;
    } else {
      teaser.find(".tile--image img").src = news.teaserImage;
    }
    teaser
      .find(".tile--date")
      .appendChild(document.createTextNode(news.publicationDate));
    teaser
      .find(".tile--category")
      .appendChild(document.createTextNode(news.newsroomCategory));
    teaser
      .find(".tile--headline")
      .appendChild(document.createTextNode(news.title ? news.title : news[`metaTitle_${language}`]));
    $(".news-teaser .row").appendChild(teaser);
    window.scrollReveal.reveal(teaser);
  });

  //finally delete the old news
  if (newSearch) {
    deleteNews(oldNews);
  }
};

/**
 * initialization of the load-more-results-button to load more news, using pagination
 */
var initLoadMoreResultsButton = function () {
  var button = document.getElementById("load-news");
  if (button !== null) {
    button.addEventListener("click", function () {
      getFilterResults(false);
    });
  }
};

/**
 * @param {*} maxResultCount the total amount of found items
 * handles the visiblity of the load-more-result-button for news
 */
var setLoadMoreResultsButtonVisibility = function (maxResultCount) {
  var button = document.getElementById("load-news");
  if (button !== null) {
    if (
      maxResultCount > resultsPerPage &&
      currentPage * resultsPerPage < maxResultCount
    ) {
      button.style.opacity = 1;
    } else {
      button.style.opacity = 0;
    }
  }
};

var generateContactsFromCountries = function (countryArray, newSearch) {
  var oldContacts = $(".accordeon-element");
  //hide the old contacts before deleting them to avoid flickering effects
  if (newSearch) {
    hideContactsToRemove(oldContacts);
  }

  countryArray.sort(function (current, next) {
    let returnValue = 0;
    const currentCountry =
      wiloconfig.haupia.countryLabels[current._source.countryId];
    const nextCountry = wiloconfig.haupia.countryLabels[next._source.countryId];
    if (currentCountry < nextCountry) {
      returnValue = -1;
    }
    if (currentCountry > nextCountry) {
      returnValue = 1;
    }
    return returnValue;
  });
  //add new contacts to the page
  countryArray.forEach(function (countryWrapper, index) {
    const contact = countryWrapper._source.contact;
    var teaser = $(".accordeon-element-template").cloneNode(true);
    removeClass(teaser, "template");
    removeClass(teaser, "accordeon-element-template");
    addClass(teaser, "accordeon-element");

    if (index === 0) {
      teaser.classList.add("active");
    }

    teaser.setAttribute("id", "ae-" + contact["website"]);
    teaser.find(".www-meta .continent").innerHTML =
      window["wiloconfig"]["haupia"]["continentLabels"][currentContinentId];
    teaser.find(".www-meta .country").innerHTML =
      window["wiloconfig"]["haupia"]["countryLabels"][
      countryWrapper._source["countryId"]
      ];
    teaser.find(".accordeon-wrapper .accordeon-title").innerHTML =
      contact["name"];

    var spanEl = document.createElement("span");
    spanEl.setAttribute("class", "icon-arrow-top");

    teaser.find(".accordeon-wrapper .accordeon-title").appendChild(spanEl);

    if (
      contact["person"] === undefined ||
      contact["person"] === "Firstname Lastname"
    ) {
      teaser.find(".www-contact .contact-person").remove();
    } else {
      teaser.find(".www-contact .contact-name").innerHTML = contact["person"];
      teaser.find(".www-contact .contact-description").innerHTML =
        contact["title"];
    }

    teaser.find(".www-contact .contact-address").innerHTML = contact["address"]
      ? contact["address"].replace("\n", "<br/>")
      : "";

    if (contact["phone"] === undefined) {
      teaser.find(".www-contact .contact-phone").remove();
    } else {
      teaser.find(".www-contact .contact-phone").innerHTML = contact["phone"];
    }

    if (contact["fax"] === undefined) {
      teaser.find(".www-contact .contact-fax").remove();
    } else {
      teaser.find(".www-contact .contact-fax").innerHTML = contact["fax"];
    }

    if (contact["email"] === undefined) {
      teaser.find(".www-contact .contact-mail").parentNode.remove();
    } else {
      teaser.find(".www-contact .contact-mail").innerHTML = contact["email"];
      teaser.find(".www-contact .contact-mail").href =
        "mailto:" + contact["email"];
    }

    if (contact["website"] === undefined) {
      teaser.find(".www-contact .contact-website").remove();
    } else {
      teaser.find(".www-contact .contact-website").href = contact["website"];
    }

    teaser.find(".accordeon-wrapper").addEventListener("click", function () {
      var oldContacts = $(".accordeon-element");
      oldContacts.forEach(function (contact) {
        contact.classList.remove("active");
      });

      teaser.classList.add("active");
    });

    $(".page-section.accordeon-section.col-12.col-md").appendChild(teaser);
    window.scrollReveal.reveal(teaser);
  });

  //finally delete the old Contacts
  if (newSearch) {
    deleteContacts(oldContacts);
  }
};

var hideContactsToRemove = function (contacts) {
  if (contacts !== undefined) {
    if (contacts instanceof NodeList) {
      contacts.forEach(function (contactsElem) {
        contactsElem.style.opacity = 0;
      });
    } else {
      contacts.style.opacity = 0;
    }
  }
};
var deleteContacts = function (contacts) {
  if (contacts !== undefined) {
    if (contacts instanceof NodeList) {
      contacts.forEach(function (contactsElem) {
        contactsElem.parentNode.removeChild(contactsElem);
      });
    } else {
      contacts.parentNode.removeChild(contacts);
    }
  }
};

/**
 * @param {*} continentId id to filter specific continents
 * @param {*} countryId id to filter specific countries
 * execute a xhr to get all contacts from haupia
 * if the response is ok generate all found contacts
 */
var getContacts = function (continentId, countryId) {
  if ($(".accordeon-element-template") !== undefined) {
    const requestUrl = elasticBaseUrl + elasticSearchIndex + "/_search";

    const terms = {
      content_type: "country",
      lang_code: language,
    };

    if (countryId) {
      terms["countryId"] = countryId;
    }
    if (continentId) {
      terms["continentId"] = continentId;
    }

    const elasticQuery = {
      query: {
        bool: {
          must: Object.entries(terms).map(([key, entry]) => {
            return {
              term: {
                [key]: entry,
              },
            };
          }),
        },
      },
      size: 1000,
    };

    fetch(requestUrl, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Basic ${btoa(
          elasticBaseAuthUser + `:` + elasticBaseAuthPw
        )}`,
        credentials: "include",
      },
      body: JSON.stringify(elasticQuery),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("HTTP status" + response.status);
        }
        return response.json();
      })
      .then((jsonResponse) => {
        if (
          jsonResponse.hits &&
          jsonResponse.hits.hits &&
          jsonResponse.hits.hits.length >= 0
        ) {
          generateContactsFromCountries(jsonResponse.hits.hits, true);
        }
        if (continentCountryMap.size > 0) {
          generateListItemsForDropdown(
            continentCountryMap.get(continentId),
            "#world-wide-country-dp",
            continentId
          );
        }
      });
  }
};

/**
 * execute a xhr to get all continents from haupia
 * if the response is ok generate all found continents into the continent dropdown
 */
var getContinents = function () {
  var continentList = $("#world-wide-continent-dp");
  if (continentList) {
    const requestUrl = elasticBaseUrl + elasticSearchIndex + "/_search";

    const elasticQuery = {
      query: {
        bool: {
          must: [
            {
              term: {
                content_type: "continent",
              },
            },
            {
              term: {
                lang_code: language,
              },
            },
          ],
        },
      },
      size: 1000,
    };

    fetch(requestUrl, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Basic ${btoa(
          elasticBaseAuthUser + `:` + elasticBaseAuthPw
        )}`,
        credentials: "include",
      },
      body: JSON.stringify(elasticQuery),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("HTTP status" + response.status);
        }
        return response.json();
      })
      .then((jsonResponse) => {
        if (
          jsonResponse.hits &&
          jsonResponse.hits.hits &&
          jsonResponse.hits.hits.length >= 0
        ) {
          generateListItemsForDropdown(
            jsonResponse.hits.hits,
            "#world-wide-continent-dp",
            null
          );
        }
      });
  }
};

/**
 *
 * @param {*} array the data to generate markup
 * @param {*} elemId element id to get the right dropdown element
 * @param {*} continentId used to start a seach query with continent filters - may be if a continent is clicked - should not be null if a country is clicked
 */
var generateListItemsForDropdown = function (array, elemId, continentId) {
  var dropdownList = $(elemId);
  if (dropdownList) {
    dropdownList.innerHTML = "";

    array = sortDropdowns(array, elemId);
    for (var i = 0; i < array.length; i++) {
      (function () {
        var id = array[i].id;
        var listElemDescription;
        var li = document.createElement("li");
        if (elemId === "#world-wide-continent-dp") {
          listElemDescription = array[i].label;
          li.innerHTML = listElemDescription;
          li.addEventListener("click", function () {
            var dropdown = parents(this, 2);
            removeClass(dropdown, "active");
            getContacts(id, null);
            $("#world-wide-continent-active").innerHTML = listElemDescription;
            currentContinentId = id;
            $("#world-wide-country-active").innerHTML =
              window["wiloconfig"]["haupia"]["selectCountryLabel"];
          });
        } else if (elemId === "#world-wide-country-dp") {
          listElemDescription = array[i].label;
          li.innerHTML = listElemDescription;

          li.addEventListener("click", function () {
            var dropdown = parents(this, 2);
            removeClass(dropdown, "active");
            getContacts(continentId, id);
            $("#world-wide-country-active").innerHTML = listElemDescription;
          });
        }
        dropdownList.appendChild(li);
      })();
    }
  }
};

/**
 * init haupia wide dropdowns
 */
var intitContinentDropdown = function () {
  var continentDropdown = $("#world-wide-continent-active");
  if (
    continentDropdown &&
    window["wiloconfig"] &&
    window["wiloconfig"]["haupia"]
  ) {
    $("#world-wide-continent-active").innerHTML =
      window["wiloconfig"]["haupia"]["continentLabels"][defaultContinentId];
  }
};

/**
 * @param {*} array the array to sort
 * @param {*} elemId element id to decide if th continent or country dropdown should be sorted
 * replaces continent / country ids with objects of continent / country with id and label
 * deletes array fields with facette count
 * sorts the array (copy)
 * sets the values of copy into a new array (result) to get rid of the deleted 'empty' values
 */
var sortDropdowns = function (array, elemId) {
  var copy = array.splice();
  var result = Array();
  if (elemId === "#world-wide-continent-dp") {
    for (var i = 0; i < array.length; i++) {
      copy[i] = {
        id: array[i]._source.continentId,
        label: array[i]._source.name,
      };
      //fill continentCountry map for later use
      continentCountryMap.set(
        array[i]._source.continentId,
        array[i]._source.countryList
      );
    }
  } else if (elemId === "#world-wide-country-dp") {
    for (var i = 0; i < array.length; i++) {
      copy[i] = {
        id: array[i],
        label: window["wiloconfig"]["haupia"]["countryLabels"][array[i]],
      };
    }
  }

  copy.sort(function (a, b) {
    var labelA = a.label.toLowerCase(),
      labelB = b.label.toLowerCase();
    if (labelA < labelB) return -1;
    if (labelA > labelB) return 1;
    return 0;
  });
  copy.forEach(function (item) {
    if (item) {
      result.push(item);
    }
  });
  return result;
};

var initGlider = function (elem, config, modal) {
  const slideClassName = "glide__slide";
  const glideTrackClassName = "glide__track";
  const controlsDataAttributeSelector = "[data-glide-el='controls']";
  const hiddenClassName = "display-none";
  const uninitializedSlideClassName = "glide__slide--uninitialized";
  const uninitializedSlidesWrapperClassName =
    "glide__slides--uninitialized";

  const slidesCount =
    elem.getElementsByClassName(glideTrackClassName)[0].
      getElementsByClassName(slideClassName).length;

  if (slidesCount > 3) {
    //show controls
    elem
      .querySelector(controlsDataAttributeSelector)
      .classList.remove(hiddenClassName);
  } else {
    if (!elem.hasAttribute("data-glide-modal")) {
      // Copy so others are not affected
      config = JSON.parse(JSON.stringify(config));
      config.perView = slidesCount;
    }
  }

  //remove css from slides
  Array.from(elem.getElementsByClassName(uninitializedSlideClassName)).forEach(
    (slide) => {
      slide.classList.remove(uninitializedSlideClassName);
    }
  );

  //remove css from slides-wrapper
  Array.from(elem.getElementsByClassName(uninitializedSlidesWrapperClassName)).forEach(
    (slidesWrapper) => {
      slidesWrapper.classList.remove(uninitializedSlidesWrapperClassName);
    }
  );

  //initalize slider
  const glide = new Glide(elem, config).mount();
  if (elem.hasAttribute("data-has-modal")) {
    const modalElem = elem.querySelector(".modal");
    elem.querySelectorAll(".glide__slide").forEach((slide) => {
      const index = slide.getAttribute("data-index");
      slide.addEventListener("click", function (event) {
        modalElem.hidden = false;
        //update to trigger resize
        modal.update();
        modal.go("=" + index);
      });
    })
  }

  return glide;
}

var initGliders = function () {
  //init content teaser slider sections if they exist on page
  const sections = document.querySelectorAll(".glide");
  const config = {
    type: "carousel",
    startAt: 0,
    perView: 3,
    gap: 24,
    animationDuration: 1000,
    breakpoints: {
      1000: {
        perView: 2,
      },
      600: {
        perView: 1,
      },
    },
  };

  sections.forEach((contentSlider) => {
    if (contentSlider.hasAttribute("data-glide-modal")) {
      return;
    }

    let modal = null;
    if (contentSlider.hasAttribute("data-has-modal")) {
      const modalConfig = {
        type: "carousel",
        startAt: 0,
        perView: 1,
        animationDuration: 1000,
      };
      const modalElement = contentSlider.querySelector("[data-glide-modal]");
      modal = initGlider(modalElement, modalConfig, null);
    }

    initGlider(contentSlider, config, modal);
  });
};

var initTnsSlider = () => {
  document.querySelectorAll('.tns')
    .forEach(node => {
      const progress = node.querySelector('.tns--progress');

      const updateProgress = instance => {
        progress.classList.toggle('hidden', (instance.pages <= 1) || (instance.slideCount <= 3));
        progress.max = instance.slideCount;
        progress.value = instance.displayIndex + instance.items - 1;
      };

      const instance = tns({
        container: node.querySelector('.tns--inner'),
        prevButton: node.querySelector('.tns--previous'),
        nextButton: node.querySelector('.tns--next'),
        nav: false,
        loop: false,
        gutter: 32,
        responsive: {
          576: { items: 1 },
          992: { items: 2 },
          1200: { items: 3 }
        },
        onInit: updateProgress
      });

      instance.events.on('newBreakpointEnd', updateProgress);
      instance.events.on('indexChanged', updateProgress);
    });
};
function shariff_share_init() {
  const wrapper = document.getElementById("shariff-wrapper");
  if (wrapper) {
    const hover = () => wrapper.classList.add("hover");
    const unhover = () => wrapper.classList.remove("hover");
    wrapper.addEventListener("click", e => {
      e.stopPropagation();
      wrapper.classList.contains("hover") ? unhover() : hover();
    });
    document.addEventListener("click", unhover);
  }
  const insertionPoint = document.getElementById("insert-shariff");
  if (insertionPoint) {
    const onScroll = () => {
      const clientRect = insertionPoint.getBoundingClientRect();
      if (clientRect.y < window.innerHeight && wrapper.style.display !== "flex") {
        wrapper.style.display = "flex";
      } else if (clientRect.y >= window.innerHeight && wrapper.style.display !== "none") {
        wrapper.style.display = "none";
      }
    };
    document.addEventListener("scroll", onScroll);
    onScroll();
  } else {
    wrapper.style.display = "flex";
  }
};


var initStageSlider = function () {
  document
    .querySelectorAll(".rt_stage_slider .rt_stage_slider__inner")
    .forEach((node) => {
      tns({
        container: node,
        autoplay: true,
        autoplayTimeout: 7500,
        autoplayButtonOutput: false,
        speed: 700,
        touch: true,
        controls: false,
        nav: true,
        swipeAngle: 45,
        navPosition: "bottom",
        preventScrollOnTouch: "auto",
        responsive: {
          576: { items: 1, mouseDrag: false },
          992: { items: 1, mouseDrag: true },
          1200: { items: 1, mouseDrag: true },
        },
      });
    });
  var slides = document.querySelectorAll(`.rt_stage_slider--item`);
  slides.forEach((slide) => {
    slide.classList.remove("d-none");
  });
};

var initModals = function () {
  document.querySelectorAll(".modal").forEach((modal) => {
    modal.querySelector(".modal--close").onclick = function () {
      modal.hidden = true;
    };
  });
}

function initAnchorLinks() {
    document.querySelectorAll('a').forEach(link => {
      link.addEventListener('click', (e) => {
          const href = link.getAttribute('href');
          if (isSamePageAnchor(href)) {
              e.preventDefault();
              const anchor = getAnchorSubstring(href);
              const targetElement = document.getElementById(anchor);
              targetElement?.scrollIntoView({ behavior: 'smooth' });
          }
      });
  });
  const isSamePageAnchor = (href) => {
      const url = new URL(href, window.location.href);
      return href.startsWith('#') || (url.origin === window.location.origin && url.pathname === window.location.pathname);
  };
  function getAnchorSubstring(href) {
    const anchorIndex = href.indexOf('#');
    return anchorIndex !== -1 ? href.substring(anchorIndex + 1) : '';
}
};

/**
 * starts initialization of all elastic-concerned buttons, dropdowns and elements
 */
window.onload = function () {
  initNews();
  initLoadMoreResultsButton();
  getContinents();
  intitContinentDropdown();
  initGliders();
  initStageSlider();
  scaleFullWidthImage();
  getContacts(defaultContinentId, null);
  initTnsSlider();
  initModals();
  initAnchorLinks();
  
  initTextOnImage();
  initTextLottie();
  initAnnualReportMap();
  addClassWhenInView('.outline-headline', 'visible', 0.75);
  addClassWhenInView('.animated-text', 'visible', 0.75);
  addClassWhenInView('.animated-quote', 'visible', 0.75);
  addClassWhenInView('.animated-text-image-section', 'visible', 0.75);
  addClassWhenInView('.key-visual', 'visible', 0.75);
  addClassWhenInView('.history-section--sponsoring .history-element', 'visible', 0.5);

  const elements = Array.from(document.querySelectorAll('.history-section--sponsoring') || []);
  elements.forEach((element) => {
    if(element.classList.contains('football')) {
      // element.style.setProperty('--asset-path', `url(./media/teaser_placeholder.jpg)`); // TODO wait for asset
    } else {
      // element.style.setProperty('--asset-path', `url(./media/teaser_placeholder.jpg)`); // TODO wait for asset
    }
  });

  

  shariff_share_init();

  if (
    continentCountryMap.size > 0 &&
    continentCountryMap.get(defaultContinentId)
  ) {
    generateListItemsForDropdown(
      continentCountryMap.get(defaultContinentId),
      "#world-wide-continent-dp",
      defaultContinentId
    );
  }

  if (document.getElementById("all-button") !== null) {
    var clickEvent = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    document.getElementById("all-button").dispatchEvent(clickEvent);
  }

  if ($("#world-wide-country-active") && window["wiloconfig"]) {
    $("#world-wide-country-active").innerHTML =
      window["wiloconfig"]["haupia"]["selectCountryLabel"];
  }
};



//Hide Promo Overlay
var initPromo = (function () {
  var closeIcon = document.getElementById("promo__overlay-close");
  if (closeIcon !== null) {
    closeIcon.addEventListener("click", function () {
      $(".promo__overlay").classList.add("promo__overlay--hide");
    });
  }

  var ctaBtn = document.getElementById("promo__cta");
  if (ctaBtn !== null) {
    ctaBtn.addEventListener("click", function (e) {
      e.preventDefault();
      $(".promo__overlay").classList.add("promo__overlay--hide");
    });
  }
})();