initFullscreenToggleButtons();
initWiloWorldSrc();
initWiloWorldFullscreenMode();

/**
 * Inits all iframe fullscreen toggle buttons with specific data attribute
 */
function initFullscreenToggleButtons () {
    const IFRAME_TOGGLE_ATTRIBUTE = 'data-iframe-fullscreen-toggle';

    let iframeFullscreenToggle = document.querySelectorAll(`[${IFRAME_TOGGLE_ATTRIBUTE}]`);

    iframeFullscreenToggle.forEach(toggle => {
        toggle.onclick = () => {
            const iframeTargetId = toggle.getAttribute(IFRAME_TOGGLE_ATTRIBUTE);
            const iframe = document.querySelector(`#${iframeTargetId}`);

            toggleClass(iframe, 'iframe-section--fullscreen');
        };
    });
}

/**
 * Inits wilo world iframe fullscreen mode if specific parameter was specified
 * in the url
 */
function initWiloWorldFullscreenMode () {
    const wiloWorldFullMode = getValueFromUrlParameter('wiloWorldFullMode');

    if (wiloWorldFullMode === 'yes') {
        const iframe = document.querySelector('#wilo-world');

        toggleClass(iframe, 'iframe-section--fullscreen');
    }
}

/**
 * Inits wilo world iframe target if specific parameter was specified in the url
 */
function initWiloWorldSrc () {
    const wiloIframe = document.querySelector('#wilo-world iframe');

    if (wiloIframe) {
        const wiloWorldTarget = getValueFromUrlParameter('wiloWorldTarget');
        let src = 'https://staging.wilo-world.oroe.eu';

        if (wiloWorldTarget) {
            src = `${src}?${wiloWorldTarget}`;
        }

        wiloIframe.setAttribute('src', src);
    }
}

/**
 * Toggles the specified class of an element
 * @param  {node}   element       The target element which should be used for the class toggle
 * @return {string} classToToggle The class which should be toggled
 */
function toggleClass (element, classToToggle) {
    let classNames = element.getAttribute('class');

    if (hasClass(classNames, classToToggle)) {
        const result = classNames.split(' ').filter((className) => {
            return (className !== classToToggle);
        });

        element.setAttribute('class', result);
    } else {
        element.setAttribute('class', `${classNames} ${classToToggle}`);
    }
};

/**
 * Checks if a class name string has specific class
 * @param  {string} classNames    The class names string which should be checked
 * @param  {string} expectedClass The class name to check
 * @return {boolean} Returns true if expected class found, otherwise false
 */
function hasClass (classNames, expectedClass) {
    return (classNames.replace(/[\n\t]/g, ' ').indexOf(expectedClass) > -1 );
};

/**
 * Gets a value from the url string
 * @param  {string} key The parameter key to get the value
 * @return {string} Returns the value of the corresponding key
 */
function getValueFromUrlParameter (key) {
    const params = window.location.search.substring(1).split('&');
    let result = null;

    params.forEach(param => {
        const keyValue = param.split('=');

        if (key === keyValue[0]) {
            result = keyValue[1];
        }
    });

    return result;
}
