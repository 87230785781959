var $ = function (selector, context) {
    var elems = (context || document).querySelectorAll(selector);
    if (elems.length > 1) {
        return elems;
    } else {
        return elems[0];
    }
}

function getElement(selector, context) {
    return (context || document).querySelector(selector);
}

function hasClass(elem, className) {
    if(!elem) return;
    return elem.classList ? elem.classList.contains(className) : new RegExp('\\b' + className + '\\b').test(elem.className);
}

function addClass(elem, className) {
    if(!elem) return;
    if (elem.classList) elem.classList.add(className);
    else if (!hasClass(elem, className)) elem.className += ' ' + className;
}

function removeClass(elem, className) {
    if(!elem) return;
    if (elem.classList) elem.classList.remove(className);
    else elem.className = elem.className.replace(new RegExp('\\b' + className + '\\b', 'g'), '');
}

function toggleClass(elem, className) {
    hasClass(elem, className) ? removeClass(elem, className) : addClass(elem, className);
}

// Maintenance
var initMaintenance = function(){
    if (getElement('.maintenance .information') !== null) {
        getElement('.maintenance .information').addEventListener('click', function() {
            toggleClass($('.maintenance__footer'), 'maintenance__footer--disabled');
        })
     }
     if (getElement('.imprint__box-close') !== null) {
        getElement('.imprint__box-close').addEventListener('click', function() {
            toggleClass($('.maintenance__footer'), 'maintenance__footer--disabled');
        })
     }

}();

//Hide Promo Overlay
var initPromo = function () {
    var closeIcon = document.getElementById('promo__overlay-close');
    if (closeIcon !== null) {
        closeIcon.addEventListener('click', function () {
            $('.promo__overlay').classList.add('promo__overlay--hide');
            $('.promo__overlay').style.display = 'none';

        });
    }

    var ctaBtn = document.getElementById('promo__cta');
    if (ctaBtn !== null) {
        ctaBtn.addEventListener('click', function (e) {
            e.preventDefault();
            $('.promo__overlay').classList.add('promo__overlay--hide');
            $('.promo__overlay').style.display = 'none';
        });
    }
}();