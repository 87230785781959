var offCanvas = {
    showOffCanvas: function(message) {
        var offCanvasContainer = document.getElementById('offcanvas');
        var offCanvasAlert = document.getElementById('off-canvas-alert');
        var offCanvasLabel = document.getElementById('off-canvas-alert-content');

        offCanvasContainer.style.display = 'block';
        offCanvasAlert.style.display = 'block';
        offCanvasLabel.insertAdjacentElement('beforeend', message);

        this.scrollToTop();
        this.addCloseOffCanvasEventListener();
    },
    hideOffCanvas: function() {
        var offCanvasContainer = document.getElementById('offcanvas');
        var offCanvasAlert = document.getElementById('off-canvas-alert');
        var offCanvasLabel = document.getElementById('off-canvas-alert-content');

        offCanvasContainer.style.display = 'none';
        offCanvasAlert.style.display = 'none';
        offCanvasLabel.innerText = '';

        this.scrollToTop();
        this.removeCloseOffCanvasEventListener();
    },
    scrollToTop: function() {
        window.scrollTo({
            'behavior': 'smooth',
            'left': 0,
            'top': document.body.offsetTop
        });
    },
    addCloseOffCanvasEventListener: function() {
        var btnClose = document.querySelectorAll('.close-off-canvas');

        for(var i = 0; i < btnClose.length; i++) {
            btnClose[i].addEventListener('click', (event) => {
                event.preventDefault();
                this.hideOffCanvas();
            });
        }
    },
    removeCloseOffCanvasEventListener: function() {
        var btnClose = document.getElementById('close-off-canvas');
        if (btnClose !== undefined && btnClose !== null) {
            btnClose.removeEventListener('click', (event) => {
                event.preventDefault();
            });
        }
    }
};

export default offCanvas;